import { Component, Inject, Input, OnDestroy, OnInit } from '@angular/core';
import { Notification } from '../interfaces/notification.interface';
import { trackById } from '../../../../utils/track-by';
import { NotificationsService } from 'src/services/notifications.service';
import moment from 'moment';

@Component({
  selector: 'vex-toolbar-notifications-dropdown',
  templateUrl: './toolbar-notifications-dropdown.component.html',
  styleUrls: ['./toolbar-notifications-dropdown.component.scss'],
})
export class ToolbarNotificationsDropdownComponent implements OnInit, OnDestroy {

  notifications: Notification[] = [];

  trackById = trackById;

  subs;

  constructor( public notis: NotificationsService ) { }

  ngOnInit() {
    this.subs = this.notis.subscribeMyNotifications().subscribe(notis => {
      if(notis) {
        this.parseNotifications(notis);
      }
      
    }, e => {
      console.error(e);
    });
  }

  ngOnDestroy(): void {
    this.subs && this.subs.unsubscribe();
    this.subs = null;
  }

  deleteNotification(e, n) {
    e.stopPropagation();
    e.preventDefault();
    console.log(e, n);
    this.notis.deleteNotification(n.id);
  }

  parseNotifications(arr) {
    let r = [];
    arr && arr.forEach(n => {
      let o = {
        id: n.id,
        title: n.title,
        message: n.message,
        icon: this.getIcon(n.type),
        colorClass: this.getClass(n.type),
        datetime: moment(n.date).format('DD/MM/YYYY HH:mm'),
        readed: n.readed
      };
      r.unshift(o);
    });
    this.notifications = r; 
  }

  orderByDate(a,b) {
    if( +(new Date(a.date).getTime()) > +(new Date(b.date).getTime()) ) {
      return -1;
    } else if( +(new Date(a.date).getTime()) < +(new Date(b.date).getTime()) ) {
      return 1;
    } else {
      return 0;
    }
  }

  getNotReadeds() {
    let r = this.notifications.filter(p => !p.readed);
    return r ? r.length : 0;
  }

  getIcon(type) {
    switch (type) {
      case 'success':
        return 'mat:check_circle_outline';
      case 'danger':
        return 'mat:error';
      case 'warning':
        return 'mat:warning';
      case 'info':
        return 'mat:info';
      default:
        return 'mat:info';
    }
  }

  getClass(type) {
    switch (type) {
      case 'success':
        return 'text-green';
      case 'danger':
        return 'text-red';
      case 'warning':
        return 'text-orange';
      case 'info':
        return 'text-blue-500';
      default:
        return 'text-blue-500';
    }
  }

  convertObjectArrayToArray(obj: any ) {
    return Object.keys(obj).map(key => {
      const ar = obj[key];
      ar['key'] = key;
      return ar;
    });
  }

  setAllReaded() {
    this.notifications.forEach(n => {
      n['readed'] = true;
    });
  }

  viewNotication(notification) {
    let o = { 
      title: notification.title,
      message: notification.message
    }
    this.notis.openDialog(o);
    this.notis.markAsReaded(notification.id);
  }

}
