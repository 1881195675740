
export function getSiteName() {
    let url = window.location.hostname;
    if(url.indexOf('belender.net') > -1) {
        return 'Belender';
    } else if(url.indexOf('bcds.net') > -1) {
        return 'BC Digital';
    }
    return 'Belender';
}

export function getCopyrightName() {
    let url = window.location.hostname;
    if(url.indexOf('belender.net') > -1) {
        return 'Belender';
    } else if(url.indexOf('bcds.net') > -1) {
        return '';
    }
    return '';
}