import { Injectable } from '@angular/core';
import { BehaviorSubject } from 'rxjs';
import { getDatabase, onChildAdded, onChildChanged, onChildRemoved, onValue, ref, set } from "firebase/database";


@Injectable({
    providedIn: 'root'
})
export class WebhooksService {

    db = getDatabase();
    exampleId = '1mlh02m8ukjhqrz1837pbrpt4';
    _userSolicitudes: BehaviorSubject<any> = new BehaviorSubject<any>(null);
    userId = 'taitoooooID';
    _bindedRequest: BehaviorSubject<any> = new BehaviorSubject<any>(null);
    _funderNotifications: BehaviorSubject<any> = new BehaviorSubject<any>(null);

    constructor() { 
        
    }

    testConnection() {
        const starCountRef = ref(this.db, 'webhooks/solicitudes');
        onValue(starCountRef, (snapshot) => {
            const data = snapshot.val();
        });
    }

    initBindSolicitudesWebhook() {

        const db = getDatabase();
        const commentsRef = ref(db, 'webhooks/solicitudes/' + this.userId);

        

        onChildAdded(commentsRef, (data) => {
            this._userSolicitudes.next( data.val() );
        });

        onChildChanged(commentsRef, (data) => {
            this._userSolicitudes.next( data.val() );
            //setCommentValues(postElement, data.key, data.val().text, data.val().author);
        });

        onChildRemoved(commentsRef, (data) => {
            this._userSolicitudes.next( data.val() );
            //deleteComment(postElement, data.key);
        });

        // const starCountRef = ref(this.db, 'webhooks/solicitudes');
        
        // return onValue(starCountRef, (snapshot) => {
        //     this._userSolicitudes.next(snapshot.val());
        // });
    }

    bindSolicitudesChangesWebhook() {
        return this._userSolicitudes.asObservable();
    }

    setSolicitudChanges(solicitud) {
        const db = getDatabase();
        set(ref(db, 'webhooks/solicitudes/' + this.userId), { data: solicitud });
    }

    saveSolicitud(idFunder, idUser, solicitud) {
        const db = getDatabase();
        return set(ref(db, `webhooks/${idFunder}/${idUser}/${solicitud.request_id}`), solicitud );
    }

    bindRequestChanges(idFunder, idUser, idRequest) {
        const db = getDatabase();
        const starCountRef = ref(db,  `webhooks/${idFunder}/${idUser}/${idRequest}`);
        onValue(starCountRef, (snapshot) => {
            this._bindedRequest.next(snapshot.val());
        });
    }

    subscribeRequestChanges() {
        return this._bindedRequest.asObservable();
    }

    subscribeFunderNotifications() {
        return this._funderNotifications.asObservable();
    }
    
}


