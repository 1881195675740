import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { BehaviorSubject } from 'rxjs';

@Injectable({ providedIn: 'root' })
export class TranslationService {

  public data: any = {};
  public loadingData = new BehaviorSubject(false);

  constructor(private http: HttpClient) {
    
  }

  getLoadingLanguage() {
    return this.loadingData.asObservable();
  }

  use(lang: string): Promise<{}> {
    this.loadingData.next(true);
    return new Promise<{}>(resolve => {
      const langPath = `./assets/lang/${lang || 'en'}.json`;

      this.http.get(langPath).subscribe(
        response => {
          localStorage.setItem('belender-language', lang);
          this.data = response || {};
          this.loadingData.next(false);
          resolve(this.data);
        },
        err => {
          this.data = {};
          resolve(this.data);
        }
      );
    });
  }
}