
import { Injectable } from '@angular/core';

@Injectable({
    providedIn: 'root'
})


export class HandleErrorsService {

    ErrorsMap = {
        birth_day: {
            status: "error",
            message: "Fecha de nacimiento incorrecta",
            code: 422
        },
        birth_date: {
            status: "error",
            message: "Fecha de nacimiento incorrecta",
            code: 422
        },
        phone: {
            status: "error",
            message: "Teléfono incorrecto",
            code: 422
        },
        document_number: {
            message: "Número de documento inválido",
            code: 422,
            status: "error"
        },
        document_expiration_date: {
            message: "Fecha de vencimiento incorrecta",
            code: 422,
            status: "error"
        },
        document_support: {
            message: "Número de documento inválido",
            code: 422,
            status: "error"
        },
        request_id: {
            message: "El código de solicitud es inválido",
            code: 422,
            status: "error"
        },
        generic: {
            status: "error",
            message: "Error desconocido",
            code: 0
        },
        code: {
            status: "error",
            messages: { 
                "The code must be 6 characters.": "El código debe ser de 6 dígitos",
                "The code must be 3 characters.": "El código debe ser de 3 dígitos",
            },
            code: 422
        }
    }

    constructor() {}

    public getError(flow, endpoint, number) {
        let n = '' + number;
        return this.ErrorsMap[flow] && this.ErrorsMap[flow][endpoint] && this.ErrorsMap[flow][endpoint][n] ? this.ErrorsMap[flow][endpoint][n] : this.getGenericMessage();
    }

    async getGenericMessage() {
        return this.ErrorsMap.generic;
    }

    async handleResponse(response) {
        
        let r = await response;
        
        if(r.status >= 200 && r.status < 300) {
            return response.json();
        } else if (r.status == 422) {
            let obj = await response.json();
            let mssg = '';
            let arr = Object.keys(obj.message);
            if(arr && arr.length) {
                arr.forEach(l => {
                    console.error(l);
                    if(this.ErrorsMap[l]) {
                        if(this.ErrorsMap[l].message) {
                            mssg += ' ' + this.ErrorsMap[l].message;
                        } else if(this.ErrorsMap[l].messages) {
                            mssg += ' ' + this.ErrorsMap[l].messages[obj.message[l][0]];
                        }
                        
                    } else {
                        mssg += ' ' + obj.message[l];
                    }
                });
            }
            let re = {
                message: mssg || obj.message,
                code: 422,
                status: "error"
            }
            return re;
        } else {
            let obj = await response.json();
            let re = this.ErrorsMap.generic;
            
            if(obj.message) {
                re.message = obj.message;
            }
            re['response'] = obj;
            re.code = +r.status;
            console.error('response from back: ', re);
            return re;
        }
    }

}



