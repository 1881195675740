import { Injectable } from '@angular/core';
import { MatSnackBar } from '@angular/material/snack-bar';
import Swal from 'sweetalert2';
import { getDatabase, onChildAdded, onChildChanged, onChildRemoved, onValue, ref, set } from "firebase/database";
import { BehaviorSubject } from 'rxjs';
import {MatDialog, MAT_DIALOG_DATA, MatDialogRef} from '@angular/material/dialog';
import { DialogComponent } from 'src/@vex/components/dialog/dialog.component';

@Injectable({
    providedIn: 'root'
})
export class NotificationsService {

    _userNotifications: BehaviorSubject<any> = new BehaviorSubject<any>([]);
    countNotifications = 0; 
    public userID = '123';

    constructor(public snackBar: MatSnackBar, public dialog: MatDialog) { }

    public showToast(mssg, duration?, type?) {
        this.snackBar.open(mssg || 'I\'m a notification!', 'Close', {
          duration: duration || 4000,
          horizontalPosition: 'right',
          verticalPosition: 'top',
          panelClass: type || ''
        });
    }

    public showAlert(title, mssg?, icon?, objActions?) {
    
          Swal.fire({  
            title: title,  
            text: mssg || '',  
            icon: icon || 'info',  
            showCancelButton: objActions ? true : false,  
            confirmButtonText: objActions && objActions.okButtonLabel ? objActions.okButtonLabel : 'OK',
            cancelButtonText: objActions && objActions.cancelButtonLabel ? objActions.cancelButtonLabel : ''
          }).then((result) => {  
            if (result.value) {  
              if(objActions && objActions.okAction) {
                objActions.okAction();
              }
            } else if (result.dismiss === Swal.DismissReason.cancel) {  
              if(objActions && objActions.cancelAction) {
                objActions.cancelAction();
              }
            }  
          });
          
    }

    public showPrompt(mssg) {
      return new Promise((resolve, reject) => {
        Swal.fire({
          title: mssg,
          input: 'text',
          inputAttributes: {
            autocapitalize: 'off'
          },
          showCancelButton: true,
          confirmButtonText: 'Enviar',
          allowOutsideClick: true
        }).then((result) => {
          if (result.isConfirmed) {
            resolve(result.value)
          } else {
            reject(false)
          }
        })
      })
      
    }

    bindFunderNotifications(userID) {
      this.userID = userID;
      const db = getDatabase();
      const starCountRef = ref(db,  `notifications/${userID}`);
      onValue(starCountRef, (snapshot) => {
        let n = snapshot.val();
        let r = n ? this.convertObjectArrayToArray(n) : [];
        if(n && this.countNotifications < r.length) {
          this.showToast('Nueva notificacion', 5000, 'info')
        }
        this.countNotifications = +(r.length);
        this._userNotifications.next(r && r.length ? r.sort((a,b) => this.sortByDate(a,b)) : []);
      });
    }

  sortByDate(a,b) {
    return +(new Date(a.date).getTime()) - +(new Date(b.date).getTime());
  }

  convertObjectArrayToArray(obj: any ) {
    return Object.keys(obj).map(key => {
      const ar = obj[key];
      ar['key'] = key;
      return ar;
    });
  }

  parseNotificationMap(obj) {
    
  }

  subscribeMyNotifications() {
      return this._userNotifications.asObservable();
  }

  markAsReaded(notificationID) {
    const db = getDatabase();
    set(ref(db, `notifications/${this.userID}/${notificationID}/readed`), true);
  }

  openDialog(data?): void {
    const dialogRef = this.dialog.open(DialogComponent, {
      data: data || null,
      width: '400px',
    });

    dialogRef.afterClosed().subscribe(result => {
      console.log('The dialog was closed');
    });
  }

  deleteNotification(notificationID) {
    const db = getDatabase();
    set(ref(db, `notifications/${this.userID}/${notificationID}`), null).then(d => {
      this.showToast('Notificación eliminada', 4000, 'danger');
    })
  }
}



