import { Injectable } from '@angular/core';
import { ActivatedRouteSnapshot, RouterStateSnapshot, UrlTree } from '@angular/router';
import { Observable } from 'rxjs';
import { AuthService } from './auth.service';
import { LoginService } from './login.service';

interface CanActivate {
    canActivate(route: ActivatedRouteSnapshot, state: RouterStateSnapshot): Observable<boolean | UrlTree> | Promise<boolean | UrlTree> | boolean | UrlTree
}

@Injectable()
export class CanActivateAdmin implements CanActivate {

  constructor(public loginS: LoginService, public auth: AuthService) {}

  canActivate(): Observable<boolean|UrlTree>|Promise<boolean|UrlTree>|boolean|UrlTree {
    return new Promise((resolve, reject) => {
      let logg = this.auth.isLogged();
      if(logg) {
        resolve(true);
      } else {
        this.loginS.tryLocalLogin().then(d => {
          resolve(true);
        }).catch(e => { 
          location.assign(location.origin + '/login');
          reject(false)
        });
      }
    });
  }
}