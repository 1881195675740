import { Pipe, PipeTransform } from '@angular/core';
import { TranslationService } from '../services/translation.service';

@Pipe({
  name: 'translate'
})
export class TranslatePipe implements PipeTransform {

  constructor(private translate: TranslationService) {
  
  }
 
  transform(value: any, page: string): any {
    return this.translate.data[page] && this.translate.data[page][value] ? this.translate.data[page][value] : '***' + value + '***';
    //return this.getValueFromKey(page, value) || '***' + value + '***';
  }

 

}
