import { ChangeDetectionStrategy, ChangeDetectorRef, Component, OnInit } from '@angular/core';
import { MenuItem } from '../interfaces/menu-item.interface';
import { trackById } from '../../../../utils/track-by';
import { PopoverRef } from '../../../../components/popover/popover-ref';
import { ConfigService } from '../../../../config/config.service';
import { Observable, map } from 'rxjs';
import { ColorSchemeName } from 'src/@vex/config/colorSchemeName';
import { VexConfig } from 'src/@vex/config/vex-config.interface';
import { LoginService } from 'src/services/login.service';
import { AuthService } from 'src/services/auth.service';

export interface OnlineStatus {
  id: 'online' | 'away' | 'dnd' | 'offline';
  label: string;
  icon: string;
  colorClass: string;
}

@Component({
  selector: 'vex-toolbar-user-dropdown',
  templateUrl: './toolbar-user-dropdown.component.html',
  styleUrls: ['./toolbar-user-dropdown.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class ToolbarUserDropdownComponent implements OnInit {

  items: MenuItem[] = [
    {
      id: '1',
      icon: 'mat:account_circle',
      label: 'Perfil',
      description: 'Información personal y seguridad',
      colorClass: 'text-teal',
      route: '/profile'
    },
    {
      id: '2',
      icon: 'mat:help',
      label: 'Ayuda',
      description: 'Consulta tus dudas aquí',
      colorClass: 'text-primary',
      route: '/help'
    },
    // {
    //   id: '3',
    //   icon: 'mat:list_alt',
    //   label: 'My Projects',
    //   description: 'Tasks & Active Projects',
    //   colorClass: 'text-amber',
    //   route: '/apps/scrumboard'
    // },
    // {
    //   id: '4',
    //   icon: 'mat:table_chart',
    //   label: 'Billing Information',
    //   description: 'Pricing & Current Plan',
    //   colorClass: 'text-purple',
    //   route: '/pages/pricing'
    // }
  ];

  statuses: OnlineStatus[] = [
    {
      id: 'online',
      label: 'Online',
      icon: 'mat:check_circle',
      colorClass: 'text-green'
    },
    {
      id: 'away',
      label: 'Away',
      icon: 'mat:access_time',
      colorClass: 'text-orange'
    },
    {
      id: 'dnd',
      label: 'Do not disturb',
      icon: 'mat:do_not_disturb',
      colorClass: 'text-red'
    },
    {
      id: 'offline',
      label: 'Offline',
      icon: 'mat:offline_bolt',
      colorClass: 'text-gray'
    }
  ];

  activeStatus: OnlineStatus = this.statuses[0];

  config$: Observable<VexConfig> = this.configService.config$;
  isDark$: Observable<boolean> = this.config$.pipe(
    map(config => config.style.colorScheme === ColorSchemeName.dark)
  );

  isDark = false;

  trackById = trackById;

  user;

  constructor(private cd: ChangeDetectorRef,
    private configService: ConfigService,
    public auth: AuthService,
    public loginS: LoginService,
    private popoverRef: PopoverRef<ToolbarUserDropdownComponent>) {}

  ngOnInit() {
    this.user = this.auth.getUserData();
  }

  setStatus(status: OnlineStatus) {
    this.activeStatus = status;
    this.cd.markForCheck();
  }

  logout() {
    this.popoverRef.close();
    this.loginS.logout();
  }

  close() {
    this.popoverRef.close();
  }

  enableDarkMode(): void {
    this.configService.updateConfig({
      style: {
        colorScheme: ColorSchemeName.dark
      }
    });
  }

  disableDarkMode(): void {
    this.configService.updateConfig({
      style: {
        colorScheme: ColorSchemeName.default
      }
    });
  }

  setDarkMode(b: boolean) {
    if (b) {
      this.disableDarkMode();
    } else {
      this.enableDarkMode();
    }
  }

  toggleDarkMode() {
    this.isDark = !this.isDark;
    localStorage.setItem('belender-dark-mode', JSON.stringify(this.isDark));
    this.setDarkMode(this.isDark);
  }

  getInitials() {
    if(this.user) {
      let arr = this.user.name.split(' ');
      let f = arr[0][0];
      let s = arr.length > 1 ? arr[1][0] : arr[0][1];
      return (f + s).toUpperCase();
    } else {
      return '';
    }
  }
}
