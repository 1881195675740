import { ChangeDetectorRef, Component, OnInit } from '@angular/core';
import { CommonModule } from '@angular/common';
import { MatIconModule } from '@angular/material/icon';
import { MatButtonModule } from '@angular/material/button';
import { MatRippleModule } from '@angular/material/core';
import { Router } from '@angular/router';
import { MatDialogRef } from '@angular/material/dialog';
import { MatMenuModule } from '@angular/material/menu';

@Component({
  standalone: true,
  imports: [CommonModule, MatIconModule, MatButtonModule, MatRippleModule, MatMenuModule],
  selector: 'vex-search-modal',
  templateUrl: './search-modal.component.html',
  styleUrls: ['./search-modal.component.scss'],
})
export class SearchModalComponent implements OnInit {

  searchedItems = [
    {
      label: 'Tito Forns',
      path: '/solicitudes',
      section: 'Todas las solicitudes',
      sectionIcon: 'assigment' 
    },
    {
      label: 'Cosme Fulanito',
      path: '/solicitudes',
      section: 'Todas las solicitudes',
      sectionIcon: 'assigment' 
    },
    {
      label: 'Roger Olivares',
      path: '/solicitudes',
      section: 'Todas las solicitudes',
      sectionIcon: 'assigment' 
    }
  ];

  flow = 'SMS';

  constructor(private route: Router, private dialogRef: MatDialogRef<SearchModalComponent>, private cd: ChangeDetectorRef) { }

  ngOnInit(): void {
  }

  gotoSection(page) {
    this.route.navigate([page]);
    this.dialogRef.close();
  }

  setFlow(f) {
    console.warn(f);
    this.flow = f;
    this.cd.markForCheck();
  }

}
