
import { Injectable } from '@angular/core';
import { AuthService } from './auth.service';
import { HandleErrorsService } from './handle-errors.service';

@Injectable({
    providedIn: 'root'
})
export class FetchService {

    constructor(public auth: AuthService, public handleErrors: HandleErrorsService) {}

    async freeGetFetch(url) {
        const response = await fetch(url);
        return this.handleErrors.handleResponse(response);
    }

    async postFile(url, data) {
        let token = this.auth.getUserToken();
        const response = await fetch(url, {
            method: 'POST',
            headers: {
                'Authorization': token
            },
            body: data
          });
          return this.handleErrors.handleResponse(response);
    }

    async getFetch(url) {
        let token = this.auth.getUserToken();
        const response = await fetch(url, {
            method: 'GET',
            mode: "cors", 
            cache: "no-cache",
            credentials: "same-origin",
            headers: {
                'Authorization': token
            },
            referrerPolicy: "no-referrer"
          });
          return this.handleErrors.handleResponse(response);
    }

    async getFetchWhitoutToken(url) {
        const response = await fetch(url, {
            method: 'GET' 
          });
          return this.handleErrors.handleResponse(response);
    }
    
    async postFetch(url, data) {
        let token = this.auth.getUserToken();
        const response = await fetch(url, {
            method: 'POST',
            headers: {
                'Authorization': token,
                'Content-Type': 'application/json'
            },
            body: JSON.stringify(data)
          });
          return this.handleErrors.handleResponse(response);
    }

    async postFetchWhitoutToken(url, data) {
        let token = this.auth.getUserToken();
        const response = await fetch(url, {
            method: 'POST',
            headers: {
                'Content-Type': 'application/json'
            },
            body: JSON.stringify(data)
          });
          return this.handleErrors.handleResponse(response);
    }

    async putFetchWhitoutToken(url, data) {
        const response = await fetch(url, {
            method: 'PUT',
            headers: {
                'Content-Type': 'application/json'
            },
            body: JSON.stringify(data)
          });
          return this.handleErrors.handleResponse(response);
    }

    async putFetch(url, data) {
        let token = this.auth.getUserToken();
        const response = await fetch(url, {
            method: 'PUT',
            headers: {
                'Authorization': token,
                'Content-Type': 'application/json'
            },
            body: JSON.stringify(data)
          });
          return this.handleErrors.handleResponse(response);
    }

    async postFileFetchWhitoutToken(url, data) {
        const response = await fetch(url, {
            method: 'POST',
            body: data
          });
          return this.handleErrors.handleResponse(response);
    }
}



