import { Injectable } from '@angular/core';
import { UtilsService } from './utils.service';
import { getDatabase, ref, child, get, onValue, set } from "firebase/database";
import { BehaviorSubject } from 'rxjs';
import { environment } from 'src/environments/environment';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { AuthService } from './auth.service';
import { FetchService } from './fetch.service';

@Injectable({
    providedIn: 'root'
})
export class CustomizationService {

    db = getDatabase();
    _custommization = new BehaviorSubject(null);

    constructor(public utils: UtilsService, private http: HttpClient, private localFetch: FetchService) {}

    bindCustomizationFromFirebase(idUser) {
        const starCountRef = ref(this.db, `customization/${idUser}`);
        onValue(starCountRef, (snapshot) => {
            const data = snapshot.val();
            this._custommization.next(data);
        });
    }

    getCustomization(funderID) {
        return new Promise((resolve, reject) => {
            this.http.get(environment.baseUrl + '/users/' + funderID + '/customization').subscribe((d: any) => {
                if(d) {
                    resolve(d);
                } else {
                    reject(d);
                }
            }, e => {
                reject(e)
            });
        });
        
    }

    subscribeCustomization() {
        return this._custommization.asObservable();
    }

    saveCustomization(funderID, obj, customizationID?) {
        const db = getDatabase();
        set(ref(db, 'customization/' + funderID), obj);
        return new Promise((resolve, reject) => {
            this.localFetch.postFetch(environment.baseUrl + '/funders/' + funderID + '/customization' , obj).then(d => {
                if(d) {
                    resolve(d);
                } else {
                    reject(false);
                }
            }).catch(e => {
                reject(e)
            });
        });

    
    }
}
