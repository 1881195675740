<div [class.container]="isVerticalLayout$ | async" class="toolbar w-full px-gutter flex items-center">
  <button (click)="openSidenav()" [class.hidden]="!mobileQuery" mat-icon-button type="button">
    <mat-icon svgIcon="mat:menu"></mat-icon>
  </button>

  <a *ngIf="isVerticalLayout$ | async" [routerLink]="['/']" class="ltr:mr-4 rtl:ml-4 flex items-center">
    <img alt="Logo" class="w-8 select-none" src="assets/img/demo/logo.svg" />
    <h1 [class.hidden]="!mobileQuery" class="title ltr:pl-4 rtl:pr-4 m-0 select-none">Belender</h1>
  </a>

  <!-- <button *ngIf="isHorizontalLayout$ | async" [class.hidden]="mobileQuery" [matMenuTriggerFor]="addNewMenu"
    color="primary" mat-flat-button type="button">{{'new' | translate:'toolbar'}}
  </button> -->

  <mat-menu #addNewMenu="matMenu" [overlapTrigger]="false" xPosition="after" yPosition="below">

    <a mat-menu-item [routerLink]="['/ui/forms/form-wizard']">
      <mat-icon svgIcon="mat:assignment_turned_in"></mat-icon>
      <span>{{'request' | translate:'toolbar'}}</span>
    </a>

  </mat-menu>

  <!-- <div #megaMenuOriginRef class="ltr:ml-2 rtl:mr-2">
    <button *ngIf="isHorizontalLayout$ | async" [class.hidden]="mobileQuery" (click)="openMegaMenu(megaMenuOriginRef)"
      [ngClass]="{ 'bg-primary/10': megaMenuOpen$ | async }" color="primary" mat-button type="button">
      <span>Mega Menu</span>
      <mat-icon class="icon-sm" svgIcon="mat:arrow_drop_down"></mat-icon>
    </button>
  </div>


  <div *ngIf="(isVerticalLayout$ | async) && isNavbarInToolbar$ | async" [class.hidden]="mobileQuery"
    class="px-gutter flex-none flex items-center">
    <vex-navigation-item *ngFor="let item of navigationItems" [item]="item"></vex-navigation-item>
  </div> -->

  <span class="flex-1"></span>

  <!-- <div class="px-1">
    <button [matMenuTriggerFor]="languageMenu" mat-icon-button type="button">
      <img class="h-6 w-6" src="assets/lang/{{languageSelected}}.png" alt="">
    </button>
  </div> -->

  <div class="px-1">
    <vex-toolbar-notifications></vex-toolbar-notifications>
  </div>

  <!-- <mat-menu #languageMenu="matMenu"  overlapTrigger="false" xPosition="before" yPosition="below">
    <button (click)="setLanguage('en')" mat-menu-item>
      <img class="h-4 w-4 mr-2" src="assets/lang/en.png" alt="">
      <span>English</span>
    </button>

    <button (click)="setLanguage('sp')" mat-menu-item>
      <img class="h-4 w-4 mr-2" src="assets/lang/sp.png" alt="">
      <span>Spanish</span>
    </button>
  </mat-menu> -->

  <div class=" flex items-center">
    
    <div  class="px-1">
      <vex-toolbar-user></vex-toolbar-user>
    </div>

  </div>
</div>

<vex-navigation *ngIf="(isVerticalLayout$ | async) && isNavbarBelowToolbar$ | async"
  [class.hidden]="mobileQuery"></vex-navigation>