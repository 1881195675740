import { Injectable } from '@angular/core';
import { UtilsService } from './utils.service';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { NotificationsService } from './notifications.service';
import { NewTrelloCard, TrelloCard } from 'src/entity/trello-card';

@Injectable({
    providedIn: 'root'
})
export class TrelloService {

    // Api key de passwheel
    // apiKey = '066550adf304d190ca15d8196ae63efa';

    // Api key de trazzo
    apiKey = 'c64f20df286ab488fb1c91a2ed3cf9ec';

    // Token de passwheel
    // token = 'f8e76ed7899a7ca46bcf01c8ddf1043251ae874a3e74249e857af4283b340966';

    // Token de trazzo
    token = 'ATTA9ff959a5d74920c598df2c9fd30314e41a5894ad23049c639cc8bba2be531d38541FAF61';

    // Board Passwheel
    // boardKey = 'QyjHbsZ7';

    // Board eventtos (trazzo)
    boardKey = '63d3d226511802fc84ab6fb3';

    basicUrl = 'https://api.trello.com/1/';


    testUrl = 'https://docs.test.nb-ric.com/api/request/clave_pin/verify';
    personalUrl = 'https://docs.test.nb-ric.com/api/request/natural_person';

    constructor(public utils: UtilsService, private http: HttpClient, private notis: NotificationsService) { 
        
    }

    getTrelloCards() {
        return this.http.get(this.basicUrl + 'boards/' + this.boardKey + '/cards?key=' + this.apiKey + '&token=' + this.token);
    }

    getInfoCard(listKey: string) {
        return this.http.get(this.basicUrl + 'lists/' + listKey + '?key=' + this.apiKey + '&token=' + this.token);
    }

    updateCard(card: TrelloCard) {
        return this.http.put(this.basicUrl + 'cards/' + card.id + '?key=' + this.apiKey + '&token=' + this.token, card);
    }

    addCard(card: NewTrelloCard) {
        return this.http.post(this.basicUrl + 'cards?idList=' + card.idList + '&keepFromSource=all&key=' + this.apiKey + '&token=' + this.token, card);
    }
}
