<div class="-m-6">
    
    <div class="flex items-center justify-between gap-4 px-6 py-3 border-b border-divider">
        <div class="text-xl flex items-center">
            Chequear si el usuario tiene flujo 
            
            <button [matMenuTriggerFor]="flowMenu" class="p-0 text-purple-500 bg-purple-light rounded-sm ml-2" mat-button type="button">
                <span class="flex justify-between items-center">
                    {{flow}}
                    <mat-icon class="ml-4" svgIcon="mat:expand_more"></mat-icon>
                </span>
            </button>
            
        </div>
        
        <button class="flex-none ltr:-mr-2 rtl:-ml-2 text-secondary" type="button" mat-icon-button>
          <mat-icon svgIcon="mat:settings"></mat-icon>
        </button>
    </div>

    <div class="flex items-center gap-4 px-6 py-3 border-b border-divider">
      <mat-icon svgIcon="mat:badge" class="text-secondary flex-none"></mat-icon>
      <input type="text" placeholder="Número de DNI o NIE" class=" font-medium bg-transparent outline-none flex-auto placeholder-secondary"/>
      
    </div>
    <div class="flex items-center gap-4 px-6 py-3 border-b border-divider">
        <mat-icon svgIcon="mat:calendar_month" class="text-secondary flex-none"></mat-icon>
        <input type="text" placeholder="Nacimiento (DD/MM/YYYY)" class=" font-medium bg-transparent outline-none flex-auto placeholder-secondary"/>
    </div>
    <div class="flex items-center gap-4 px-6 py-3 border-b border-divider">
        <mat-icon svgIcon="mat:phone" class="text-secondary flex-none"></mat-icon>
        <input type="text" placeholder="Telefono" class=" font-medium bg-transparent outline-none flex-auto placeholder-secondary"/>
    </div>

    <div class="modal-content">

        <div cdkFocusInitial (click)="gotoSection('dfd')" class="px-2 py-3 hover:bg-hover transition duration-200 ease-out flex items-center cursor-pointer select-none"
                    matRipple>
            <div class="flex-auto text-base font-medium"></div>
            <div class="flex-none text-md text-secondary font-medium flex items-center gap-2">
                <div><strong>Ver si tiene PIN</strong></div>
                
            </div>
            <mat-icon svgIcon="mat:chevron_right" class="icon-sm flex-none mr-2 ml-2"></mat-icon>
        </div>

        <!-- <div class="p-4">
            <div class="text-xs font-semibold text-secondary px-2 mb-2">Resultados de la búsqueda</div>
    
            <div class="space-y-1">

                <ng-container  *ngFor="let item of searchedItems">
                    <div (click)="gotoSection(item.path)" class="px-2 py-2 hover:bg-hover rounded transition duration-200 ease-out flex items-center gap-4 cursor-pointer select-none"
                    matRipple>
                        <div class="flex-auto text-base font-medium">{{item.label}}</div>
                        <div class="flex-none text-xs text-secondary font-medium flex items-center gap-2">
                            <div>{{item.section}}</div>
                            <mat-icon svgIcon="mat:{{item.sectionIcon}}" class="icon-xs flex-none"></mat-icon>
                        </div>
                        <mat-icon svgIcon="mat:chevron_right" class="icon-sm flex-none"></mat-icon>
                    </div>
                </ng-container>

                <ng-container *ngIf="!searchedItems || searchedItems.length == 0">
                    <p class="text-hint py-4 px-6">No hay items que coincidan con tu búsqueda</p>
                </ng-container>
        
            </div>
        </div>
      
        <div class="p-4">
        <div class="text-xs font-semibold text-secondary px-2 mb-2">Accesos directos</div>
    
        <div class="space-y-1">
    
            <div (click)="gotoSection('/solicitudes')" class="px-2 py-2 hover:bg-hover rounded transition duration-200 ease-out flex items-center gap-4 cursor-pointer select-none"
                matRipple>
            <div class="flex items-center justify-center w-8 h-8 rounded-full bg-foreground/20">
                <mat-icon svgIcon="mat:assignment" class="icon-sm flex-none"></mat-icon>
            </div>
            <div class="flex-auto text-base font-medium">
                <div>Todas las solicitudes</div>
                <div class="text-secondary text-xs">/solicitudes</div>
            </div>
            <mat-icon svgIcon="mat:chevron_right" class="icon-sm flex-none"></mat-icon>
            </div>
    
            <div (click)="gotoSection('/ui/forms/form-wizard')" class="px-2 py-2 hover:bg-hover rounded transition duration-200 ease-out flex items-center gap-4 cursor-pointer select-none"
                matRipple>
            <div class="flex items-center justify-center w-8 h-8 rounded-full bg-foreground/20">
                <mat-icon svgIcon="mat:add_circle" class="icon-sm flex-none"></mat-icon>
            </div>
            <div class="flex-auto text-base font-medium">
                <div>Nueva solicitud</div>
                <div class="text-secondary text-xs">/ui/forms/form-wizard</div>
            </div>
            <mat-icon svgIcon="mat:chevron_right" class="icon-sm flex-none"></mat-icon>
            </div>
    
            <div (click)="gotoSection('/customization')" class="px-2 py-2 hover:bg-hover rounded transition duration-200 ease-out flex items-center gap-4 cursor-pointer select-none"
                matRipple>
            <div class="flex items-center justify-center w-8 h-8 rounded-full bg-foreground/20">
                <mat-icon svgIcon="mat:format_paint" class="icon-sm flex-none"></mat-icon>
            </div>
            <div class="flex-auto text-base font-medium">
                <div>Customización</div>
                <div class="text-secondary text-xs">/customization</div>
            </div>
            <mat-icon svgIcon="mat:chevron_right" class="icon-sm flex-none"></mat-icon>
            </div>
    
            <div (click)="gotoSection('/help/documents')" class="px-2 py-2 hover:bg-hover rounded transition duration-200 ease-out flex items-center gap-4 cursor-pointer select-none"
                matRipple>
            <div class="flex items-center justify-center w-8 h-8 rounded-full bg-foreground/20">
                <mat-icon svgIcon="mat:help" class="icon-sm flex-none"></mat-icon>
            </div>
            <div class="flex-auto text-base font-medium">
                <div>Ayuda</div>
                <div class="text-secondary text-xs">/help/documents</div>
            </div>
            <mat-icon svgIcon="mat:chevron_right" class="icon-sm flex-none"></mat-icon>
            </div>
    
        </div>
        </div> -->
      
         
    </div>
  </div>

  <mat-menu #flowMenu="matMenu" xPosition="before" yPosition="below">


    <button mat-menu-item (click)="setFlow('SMS')">
        <span >SMS</span>
    </button>
    <button mat-menu-item (click)="setFlow('PIN')">
        <span >PIN</span>
    </button>
    <button mat-menu-item (click)="setFlow('VIDEO')">
        <span >VIDEO ID</span>
    </button>
  
  
  </mat-menu>

