import { AfterViewChecked, Component, Inject, LOCALE_ID, Renderer2 } from '@angular/core';
import { ConfigService } from '../@vex/config/config.service';
import { Settings } from 'luxon';
import { DOCUMENT } from '@angular/common';
import { Platform } from '@angular/cdk/platform';
import { NavigationService } from '../@vex/services/navigation.service';
import { LayoutService } from '../@vex/services/layout.service';
import { ActivatedRoute } from '@angular/router';
import { coerceBooleanProperty } from '@angular/cdk/coercion';
import { SplashScreenService } from '../@vex/services/splash-screen.service';
import { VexConfigName } from '../@vex/config/config-name.model';
import { ColorSchemeName } from '../@vex/config/colorSchemeName';
import { MatIconRegistry, SafeResourceUrlWithIconOptions } from '@angular/material/icon';
import { DomSanitizer, SafeResourceUrl } from '@angular/platform-browser';
import { ColorVariable, colorVariables } from '../@vex/components/config-panel/color-variables';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { UtilsService } from 'src/services/utils.service';
import { TranslationService } from 'src/services/translation.service';
import { ChangeDetectorRef } from '@angular/core';
import { DeepPartial } from 'src/@vex/interfaces/deep-partial.type';
import { TrelloService } from 'src/services/trello.service';
import { LoginService } from 'src/services/login.service';
import { NavigationItem, NavigationSubheading } from 'src/@vex/interfaces/navigation-item.interface';
import { AuthService } from 'src/services/auth.service';
import { WebhooksService } from 'src/services/webhooks.service';
import { NotificationsService } from 'src/services/notifications.service';
import { getSiteName } from 'src/static-data/location-variables';

@Component({
  selector: 'vex-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.scss']
})
export class AppComponent implements AfterViewChecked {

  public loading: any = false;
  systemLoading = null;
  public financiadorID;
  public userID;

  constructor(private configService: ConfigService,
    private renderer: Renderer2,
    private platform: Platform,
    @Inject(DOCUMENT) private document: Document,
    @Inject(LOCALE_ID) private localeId: string,
    private auth: AuthService,
    private navigationService: NavigationService,
    private splashScreenService: SplashScreenService,
    private readonly matIconRegistry: MatIconRegistry,
    private readonly domSanitizer: DomSanitizer,
    private notiService: NotificationsService,
    public utils: UtilsService,
    private cdRef:ChangeDetectorRef,
    public translateService: TranslationService,
    public trelloService: TrelloService,
    public webhooksService: WebhooksService
  ) {

    this.systemLoading = 'Cargando sistema';
    this.auth.bindUserData().subscribe(d => {
      if(d) {
        this.financiadorID = d.funder_id;
        this.userID = d.user_id;
        this.setSidenavItems();
        this.bindFirebaseNotifications(d.funder_id);
        
      }
      this.systemLoading = null;
    })

    Settings.defaultLocale = this.localeId;


    this.matIconRegistry.addSvgIconResolver(
      (
        name: string,
        namespace: string
      ): SafeResourceUrl | SafeResourceUrlWithIconOptions | null => {
        switch (namespace) {
          case 'mat':
            return this.domSanitizer.bypassSecurityTrustResourceUrl(
              `assets/img/icons/material-design-icons/two-tone/${name}.svg`
            );

          case 'logo':
            return this.domSanitizer.bypassSecurityTrustResourceUrl(
              `assets/img/icons/logos/${name}.svg`
            );

          case 'flag':
            return this.domSanitizer.bypassSecurityTrustResourceUrl(
              `assets/img/icons/flags/${name}.svg`
            );
        }
      }
    );

    const color: ColorVariable = colorVariables['gray'];
    let sidenavState = null; 

    if(localStorage.getItem('belender-sidenav-closed')) {
      sidenavState = JSON.parse(localStorage.getItem('belender-sidenav-closed')) ? 'collapsed' : 'expanded' ;
    } 

    if (color) {
      this.configService.updateConfig({
        style: {
          colors: {
            primary: color
          }
        },
        sidenav: {
          state: sidenavState || 'collapsed'
        }
      });
    }
    
  }

  ngAfterViewChecked() {
    this.utils.getLoading().subscribe(b => {
      this.loading = b;
      this.cdRef.detectChanges();
    });
    let sn = getSiteName();
    document.title = sn;
  }

  bindFirebaseNotifications(funderID) {
    this.notiService.bindFunderNotifications(funderID);
  }

  setSidenavItems() {
    this.navigationService.items = [
      this.getRequestZone(),
      this.getConfigZone(),
      //this.getAdminZone(),
      //this.getKycZone(),
      //this.getWidgetItem(),
    ];
  }

  getRequestZone(): NavigationSubheading {
    return  {
      type: 'subheading',
      label: 'SOLICITUDES',
      children: [
        {
          type: 'link',
          label: this.translateService.data.sidenav.all_requests,
          route: '/solicitudes/all',
          icon: 'mat:assignment'
        },
        {
          type: 'link',
          label: this.translateService.data.sidenav.new_request,
          route: '/solicitudes/new',
          icon: 'mat:add_circle',
          className: 'text-purple-500'
        },
      ]
    }
  }
  

  getWidgetItem(): NavigationSubheading {
    return {
      type: 'subheading',
      label: 'Widget',
      children: [
        {
          type: 'link',
          label: 'Widget',
          route: '/widget-boxed/sms/' + this.userID + '/626bc43f-f0e2-464b-b550-b5cd3a8c24d4' ,
          icon: 'mat:extension',
          target: '_blank'
        }
      ]
    }
  }

  getConfigZone(): NavigationSubheading {
    let obj: NavigationSubheading = {
      type: 'subheading',
      label: this.translateService.data.sidenav.config,
      children: [
        {
          type: 'link',
          label: this.translateService.data.sidenav.customization,
          route: 'config/customization',
          icon: 'mat:format_paint'
        }
      ]
    }
    return obj;
  }

  getAdminZone(): NavigationSubheading {
    let obj: NavigationSubheading = {
      type: 'subheading',
      hide: true,
      label: 'Admin config',
      children: []
    };
    if(this.auth.getUserRole() == 'BackOffice') {
      obj.hide = false;
      obj.children.push(
        {
          type: 'link',
          label: 'Testing',
          route: 'admin-config/test',
          icon: 'mat:info'
        }
      )
      obj.children.push(
        {
          type: 'link',
          label: 'Files',
          route: 'admin-config/files-table',
          icon: 'mat:attachment'
        }
      )
      obj.children.push(
        {
          type: 'link',
          label: 'Users',
          route: 'admin-config/users',
          icon: 'mat:supervised_user_circle'
        }
      )
    }
    return obj;
  }

  getKycZone(): NavigationSubheading {
    let obj: NavigationSubheading = {
      type: 'subheading',
      hide: true,
      label: 'KYC',
      children: []
    };
    if(this.auth.getUserRole() == 'BackOffice') {
      obj.hide = false;
      obj.children.push(
        {
          type: 'link',
          label: 'Customizate',
          route: 'kyc/custom',
          icon: 'mat:info'
        },
        {
          type: 'link',
          label: 'Form',
          route: 'kyc/form',
          icon: 'mat:info'
        }
      )
      
    }
    return obj;
  }
 
}
