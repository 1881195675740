import { NgModule } from '@angular/core';
import { RouterModule } from '@angular/router';
import { WidgetBoxedLayoutComponent } from './custom-layout/widget-boxed-layout/widget-boxed-layout.component';
import { VexRoutes } from '../@vex/interfaces/vex-route.interface';
import { QuicklinkModule, QuicklinkStrategy } from 'ngx-quicklink';
import { CanActivateTeam } from 'src/services/canActivateRoute.service';
import { CanActivateAdmin } from 'src/services/canActivateAdmin.service';


const routes: VexRoutes = [
  
  {
    path: 'widget',
    loadChildren: () => import('./pages/widget/widget.module').then(m => m.WidgetModule),
  },
  {
    path: 'widget-boxed',
    component: WidgetBoxedLayoutComponent,
    children: [
      {
        path: '',
        loadChildren: () => import('./custom-layout/widget-boxed-layout/widget-boxed-layout.module').then(m => m.WidgetBoxedLayoutModule),
      }
    ]
  },
  {
    path: 'terms',
    loadChildren: () => import('./pages/terms/terms.module').then(m => m.TermsModule),
  },
  {
    path: 'terms-certificate',
    loadChildren: () => import('./pages/terms-certificate/terms.module').then(m => m.TermsCertModule),
  },
  {
    path: 'policy',
    loadChildren: () => import('./pages/policy/policy.module').then(m => m.PolicyModule),
  },
  {
    path: 'legal',
    loadChildren: () => import('./pages/legal/legal.module').then(m => m.LegalModule),
  },
  {
    path: 'support-number',
    loadChildren: () => import('./pages/support-number/support-number.module').then(m => m.SupportNumberModule),
  },
];

@NgModule({
  imports: [
    RouterModule.forRoot(routes, {
    preloadingStrategy: QuicklinkStrategy,
    scrollPositionRestoration: 'enabled',
    relativeLinkResolution: 'corrected',
    anchorScrolling: 'enabled'
  })

  ],
  exports: [RouterModule, QuicklinkModule],
  providers: [CanActivateTeam, CanActivateAdmin]
})
export class AppRoutingModule {
}
