import { Injectable } from '@angular/core';
import { BehaviorSubject, Observable } from 'rxjs';
import UuidEncoder from 'uuid-encoder';
import { AppComponent } from 'src/app/app.component';

// Create Base 36 encoder
const encoder = new UuidEncoder('base36');

@Injectable({
    providedIn: 'root'
})
export class UtilsService {

    private loading = new BehaviorSubject<any>(false);
    private WarningsSpaces = new BehaviorSubject<number>(0);

    constructor() { }

    setLoading(b: any) {
        this.loading.next(b);
    }

    getLoading() {
        return this.loading.asObservable();
    }

    setWarningsSpaces(b: number) {
        this.WarningsSpaces.next(b);
    }

    getWarningsSpaces() {
        return this.WarningsSpaces.asObservable();
    }

    public uuidv4() {
        return encoder.encode(this.uuidv4Aux());
    }

    uuidv4Aux() {
        return 'xxxxxxxx-xxxx-4xxx-yxxx-xxxxxxxxxxxx'.replace(/[xy]/g, function(c) {
            var r = (Math.random() * 16) | 0,
                v = c == 'x' ? r : (r & 0x3) | 0x8;
            return v.toString(16);
        });
    }
}
